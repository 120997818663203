import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  static targets = ["document"]

  connect() {
    const documentMaskBehavior = function (val) {
        return val.replace(/\D/g, "").length > 11 ? "00.000.000/0000-00" : "000.000.000-009";
      },
      documentMaskOptions = {
        onKeyPress: function (val, e, field, options) {
          field.mask(documentMaskBehavior.apply({}, arguments), options);
        }
      };
    $(this.documentTarget).mask(documentMaskBehavior, documentMaskOptions);
  }
}
