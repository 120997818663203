import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["zip", "street", "complement", "neighborhood", "states", "cities"]

  autocomplete(event) {
    const input = event.target;
    const zip = input.value;
    this.addLoadingClass(input);

    Rails.ajax({
      type: "GET",
      url: "/api/address",
      data: `zip=${zip}`,
      success: address => {
        if (Object.keys(address).length > 0) {
          this.fillAddress(address);
        }
      },
      complete: () => {
        this.removeLoadingClass(input);
      }
    });
  }

  reloadCities(event) {
    const stateId = event.target.value;
    this.refreshCities(stateId);
  }

  fillAddress(address) {
    this.fillStreet(address.street);
    this.fillComplement(address.complement);
    this.fillNeighborhood(address.neighborhood);
    if (address.hasOwnProperty("city")) {
      this.fillCity(address.city);
    }
  }

  fillStreet(street) {
    if (this.hasStreetTarget) {
      this.streetTarget.value = street;
    }
  }

  fillComplement(complement) {
    if (this.hasComplementTarget) {
      this.complementTarget.value = complement;
    }
  }

  fillNeighborhood(neighborhood) {
    if (this.hasNeighborhoodTarget) {
      this.neighborhoodTarget.value = neighborhood;
    }
  }

  fillCity(city) {
    if (this.hasStatesTarget) {
      this.selectState(city.state);

      if (this.hasCitiesTarget) {
        this.refreshCities(city.state.id, function () {
          this.selectCity(city);
        })
      }
    }
  }

  refreshCities(stateId, callback) {
    const select = this.citiesTarget;
    this.addLoadingClass(select);

    Rails.ajax({
      type: "GET",
      url: "/api/cities",
      data: `state_id=${stateId}`,
      success: cities => {
        let options = '<option value=""></option>';

        for (const city of cities) {
          options += `<option value="${city.id}">${city.name}</option>`
        }

        select.innerHTML = options;

        if (typeof callback === "function") {
          callback.call(this);
        }
      },
      complete: () => {
        this.removeLoadingClass(select);
      }
    })
  }

  selectState(state) {
    const statesOptions = this.statesTarget.options;

    for (const stateOption of statesOptions) {
      if (stateOption.value == state.id) {
        stateOption.selected = true
        return;
      }
    }
  }

  selectCity(city) {
    const citiesOptions = this.citiesTarget.options;

    for (const cityOption of citiesOptions) {
      if (cityOption.value == city.id) {
        cityOption.selected = true
        return;
      }
    }
  }

  addLoadingClass(element) {
    element.classList.add("loading");
  }

  removeLoadingClass(element) {
    element.classList.remove("loading");
  }
}
