// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable'
import 'regenerator-runtime/runtime'

require("@rails/ujs").start();
// require("@rails/activestorage").start();
require("turbolinks").start();
// require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "../stylesheets/application"

// import "bootstrap"
import "bootstrap/js/src/tooltip"

import "controllers"
import "jquery-mask-plugin"

import "@fortawesome/fontawesome-pro/js/solid"
import "@fortawesome/fontawesome-pro/js/regular"
// import "@fortawesome/fontawesome-pro/js/light"
import "@fortawesome/fontawesome-pro/js/duotone"
import "@fortawesome/fontawesome-pro/js/brands"
import "@fortawesome/fontawesome-pro/js/fontawesome"

// https://fontawesome.com/how-to-use/on-the-web/using-with/turbolinks
FontAwesome.config.mutateApproach = "sync";

// Carrega todas as imagens de `images`
require.context("../images", true);

import config from "../application/config";
config.start();
