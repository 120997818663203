import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (window.location.hash) {
      const shoppingCartItemId = window.location.hash.substring(1)
      this.focusOnShoppingCartItem(shoppingCartItemId)
    }
  }

  focusOnShoppingCartItem(shoppingCartItem) {
    const cartItemId = `shopping_cart_item_${shoppingCartItem}`
    const cartItem = document.getElementById(cartItemId);
    const quantityInput = cartItem.querySelector("input.quantity")
    quantityInput.focus();
  }
}
