// Validação JavaScript que utiliza os estilos
// do Bootstrap para exibição dos erros.

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const form = this.element;
    form.addEventListener("submit",
      this.validate.bind(this), true);
  }

  validate(event) {
    const form = event.target;

    if (form.checkValidity() === false) {
      const invalidFields = form.querySelectorAll(":invalid");

      this.displayInvalidFeedback(invalidFields);
      this.focusOnFirstInvalidField(invalidFields);

      event.preventDefault();
      event.stopPropagation();
    }

    form.classList.add("was-validated");
  }

  displayInvalidFeedback(invalidFields) {
    Array.prototype.filter.call(invalidFields, (invalidField) => {
      const invalidFeedback = this.findOrCreateInvalidFeedback(invalidField);
      invalidFeedback.innerHTML = invalidField.validationMessage;
      // oculta elemento até encontrar uma forma de
      // exibir as mensagens de validação traduzidas
      invalidFeedback.style.display = "none";
    });
  }

  findOrCreateInvalidFeedback(invalidField) {
    let invalidFeedback = this.findInvalidFeedback(invalidField);

    if (invalidFeedback == null) {
      invalidFeedback = this.createInvalidFeedback(invalidField);
    }

    return invalidFeedback;
  }

  findInvalidFeedback(invalidField) {
    return invalidField.parentElement.querySelector(".invalid-feedback");
  }

  createInvalidFeedback(invalidField) {
    const invalidFeedback = document.createElement("div");
    invalidFeedback.className = "invalid-feedback";

    this.insertInvalidFeedback(invalidFeedback, invalidField);

    return invalidFeedback;
  }

  insertInvalidFeedback(invalidFeedback, invalidField) {
    const invalidFieldParent = invalidField.parentElement;

    if (invalidFieldParent.classList.contains("input-group") ||
      invalidFieldParent.classList.contains("form-check") ||
      invalidFieldParent.classList.contains("custom-control")) {
      // insere como último filho do elemento pai
      invalidFieldParent.insertBefore(invalidFeedback, null);
    }
    else {
      // esperado .form-text ou null
      const invalidFieldSibling = invalidField.nextSibling;
      // insere logo após campo inválido
      invalidFieldParent.insertBefore(invalidFeedback, invalidFieldSibling);
    }
  }

  focusOnFirstInvalidField(invalidFields) {
    const firstInvalidField = invalidFields[0];
    const animationClass = "vibrate-1";

    firstInvalidField.addEventListener("animationend", () => {
      firstInvalidField.classList.remove(animationClass);
    });

    firstInvalidField.classList.add(animationClass);
    firstInvalidField.focus();
  }
}
