import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (this.confirmDialog()) {
      const whatsAppUrl = this.data.get("url");
      Turbolinks.visit(whatsAppUrl);
    }
  }

  confirmDialog() {
    return confirm("Seu pedido foi registrado e está pronto para ser " +
      "enviado via WhatsApp. Clique em \"OK\" para fazer isso agora.")
  }
}
